export const medicationDestructionItemReasons = [
  {
    id: 'expired',
    name: 'Expired'
  },
  {
    id: 'discontinued',
    name: 'Discontinued'
  },
  {
    id: 'resident-discharged',
    name: 'Resident Discharged'
  },
  {
    id: 'resident-deceased',
    name: 'Resident Deceased'
  },
  {
    id: 'refused-by-resident',
    name: 'Refused by Resident'
  },
  {
    id: 'contamination',
    name: 'Contamination'
  },
  {
    id: 'found',
    name: 'Found'
  },
  {
    id: 'other',
    name: 'Other'
  }
] as const;

export const medicationDestructionItemReasonIds =
  medicationDestructionItemReasons.map(({ id }) => id);

export type MedicationDestructionItemReasonId =
  (typeof medicationDestructionItemReasonIds)[number];

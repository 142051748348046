export const medicationTaskStates = [
  {
    id: 'created',
    name: 'Created'
  },
  {
    id: 'pop',
    name: 'Prep'
  },
  {
    id: 'pass',
    name: 'Give'
  },
  {
    id: 'popOmitted',
    name: 'Prep Omitted'
  },
  {
    id: 'passOmitted',
    name: 'Give Omitted'
  },
  {
    id: 'omit',
    name: 'Omit'
  }
] as const;

export const medicationTaskStateIds = medicationTaskStates.map(({ id }) => id);

export type MedicationTaskStateId = (typeof medicationTaskStateIds)[number];

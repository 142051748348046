export const bodyPlanes = [
  {
    id: 'front-left',
    name: 'Front Left',
    frontal: 'front',
    lateral: 'left',
    horizontal: ''
  },
  {
    id: 'front-middle',
    name: 'Front Middle',
    frontal: 'front',
    lateral: 'middle',
    horizontal: ''
  },
  {
    id: 'front-right',
    name: 'Front Right',
    frontal: 'front',
    lateral: 'right',
    horizontal: ''
  },
  {
    id: 'back-left',
    name: 'Back Left',
    frontal: 'back',
    lateral: 'left',
    horizontal: ''
  },
  {
    id: 'back-middle',
    name: 'Back Middle',
    frontal: 'back',
    lateral: 'middle',
    horizontal: ''
  },
  {
    id: 'back-right',
    name: 'Back Right',
    frontal: 'back',
    lateral: 'right',
    horizontal: ''
  }
] as const;

export const bodyPlaneIds = bodyPlanes.map(({ id }) => id);

export type BodyPlaneId = (typeof bodyPlaneIds)[number];

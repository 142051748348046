export const incidentReportNotificationRecipientTypes = [
  {
    id: 'licensing',
    name: 'Licensing',
    stateId: 'US-CA',
    pdfFieldName: 'Agency icensing Name'
  },
  {
    id: 'ombudsman',
    name: 'Long Term Care Ombudsman',
    stateId: 'US-CA',
    pdfFieldName: 'Agency Leasing2'
  },
  {
    id: 'law-enforcement',
    name: 'Law Enforcement',
    stateId: 'US-CA',
    pdfFieldName: 'Agency Leasing3'
  },
  {
    id: 'protective-services',
    name: 'Adult/Child Protective Services',
    stateId: 'US-CA',
    pdfFieldName: 'Adult/Child'
  },
  {
    id: 'guardian',
    name: 'Parent/Guardian/Conservator',
    stateId: 'US-CA',
    pdfFieldName: 'Adult/Child2'
  },
  {
    id: 'placement-agency',
    name: 'Placement Agency',
    stateId: 'US-CA',
    pdfFieldName: 'Adult/Child3'
  }
] as const;

export const IncidentReportNotificationRecipientTypeIds =
  incidentReportNotificationRecipientTypes.map(({ id }) => id);

export type IncidentReportNotificationRecipientTypeId =
  (typeof IncidentReportNotificationRecipientTypeIds)[number];

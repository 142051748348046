export const documentTypes = [
  {
    id: 'gen',
    name: 'General'
  },
  {
    id: 'company',
    name: 'Company'
  },
  {
    id: 'lic',
    name: 'Licensing'
  },
  {
    id: 'gov',
    name: 'Government'
  },
  {
    id: 'train',
    name: 'Training'
  }
] as const;

export const documentTypeIds = documentTypes.map(({ id }) => id);

export type DocumentTypeId = (typeof documentTypeIds)[number];

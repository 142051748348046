export const diagnosisLevels = [
  {
    id: 'mild',
    name: 'Mild'
  },
  {
    id: 'moderate',
    name: 'Moderate'
  },
  {
    id: 'severe',
    name: 'Severe'
  }
] as const;

export const diagnosisLevelIds = diagnosisLevels.map(({ id }) => id);

export type DiagnosisLevelId = (typeof diagnosisLevelIds)[number];

export const prnResponses = [
  {
    id: 'improved',
    name: 'Improved',
    description: 'Effective / Improved'
  },
  {
    id: 'nochange',
    name: 'No Change',
    description: 'Ineffective / No Change'
  },
  {
    id: 'worse',
    name: 'Worse',
    description: 'Negative Effect / Worse'
  }
] as const;

export const prnResponseIds = prnResponses.map(({ id }) => id);

export type PrnResponseId = (typeof prnResponseIds)[number];

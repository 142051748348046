export const careTaskStates = [
  {
    id: 'scheduled',
    name: 'Scheduled'
  },
  {
    id: 'completed',
    name: 'Completed'
  },
  {
    id: 'omitted',
    name: 'Omitted'
  }
] as const;

export const careTaskStateIds = careTaskStates.map(({ id }) => id);

export type CareTaskStateId = (typeof careTaskStateIds)[number];

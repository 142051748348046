export const temperatureUnits = [
  {
    id: 'fahrenheit',
    abbreviation: '°F',
    name: 'Fahrenheit'
  },
  {
    id: 'celsius',
    abbreviation: '°C',
    name: 'Celsius'
  }
] as const;

export const temperatureUnitIds = temperatureUnits.map(({ id }) => id);

export type TemperatureUnitId = (typeof temperatureUnitIds)[number];

export const incidentTypes = [
  {
    id: 'unauthorized-absence',
    name: 'Unauthorized Absence',
    group: 'General',
    stateId: 'US-CA',
    pdfFieldName: 'Type of Incident'
  },
  {
    id: 'aggressive-act-self',
    name: 'Aggressive Act to Self',
    group: 'General',
    stateId: 'US-CA',
    pdfFieldName: 'Incident2'
  },
  {
    id: 'aggressive-act-client',
    name: 'Aggressive Act to Another Client',
    group: 'General',
    stateId: 'US-CA',
    pdfFieldName: 'Incident3'
  },
  {
    id: 'aggressive-act-staff',
    name: 'Aggressive Act to Staff',
    group: 'General',
    stateId: 'US-CA',
    pdfFieldName: 'Incident4'
  },
  {
    id: 'aggressive-act-visitors',
    name: 'Aggressive Act to Family/Visitors',
    group: 'General',
    stateId: 'US-CA',
    pdfFieldName: 'Incident5'
  },
  {
    id: 'rights-violation',
    name: 'Alleged Violation of Rights',
    group: 'General',
    stateId: 'US-CA',
    pdfFieldName: 'Incident6'
  },
  {
    id: 'client-abuse-sexual',
    name: 'Sexual',
    group: 'Alleged Client Abuse',
    stateId: 'US-CA',
    pdfFieldName: 'alleged client abuse'
  },
  {
    id: 'client-abuse-physical',
    name: 'Physical',
    group: 'Alleged Client Abuse',
    stateId: 'US-CA',
    pdfFieldName: 'Alleged Client Abuse2'
  },
  {
    id: 'client-abuse-psychological',
    name: 'Psychological',
    group: 'Alleged Client Abuse',
    stateId: 'US-CA',
    pdfFieldName: 'Alleged Client Abuse3'
  },
  {
    id: 'client-abuse-financial',
    name: 'Financial',
    group: 'Alleged Client Abuse',
    stateId: 'US-CA',
    pdfFieldName: 'Alleged Client Abuse4'
  },
  {
    id: 'client-abuse-neglect',
    name: 'Neglect',
    group: 'Alleged Client Abuse',
    stateId: 'US-CA',
    pdfFieldName: 'Alleged Client Abuse5'
  },
  {
    id: 'client-abuse-rape',
    name: 'Rape',
    group: 'Alleged Client Abuse',
    stateId: 'US-CA',
    pdfFieldName: 'Alleged Client Abuse6'
  },
  {
    id: 'client-abuse-pregnancy',
    name: 'Pregnancy',
    group: 'Alleged Client Abuse',
    stateId: 'US-CA',
    pdfFieldName: 'Alleged Client Abuse7'
  },
  {
    id: 'client-abuse-suicide-attempt',
    name: 'Suicide Attempt',
    group: 'Alleged Client Abuse',
    stateId: 'US-CA',
    pdfFieldName: 'Alleged Client Abuse8'
  },
  {
    id: 'client-abuse-other',
    name: 'Other Abuse',
    group: 'Alleged Client Abuse',
    stateId: 'US-CA',
    pdfFieldName: 'Alleged Client Abuse9'
  },
  {
    id: 'injury-accident',
    name: 'Injury from Accident',
    group: 'General',
    stateId: 'US-CA',
    pdfFieldName: 'Client Abuse10'
  },
  {
    id: 'injury-unknown-origin',
    name: 'Injury from Unknown Origin',
    group: 'General',
    stateId: 'US-CA',
    pdfFieldName: 'Client Abuse11'
  },
  {
    id: 'injury-from-other-client',
    name: 'Injury from Other Client',
    group: 'General',
    stateId: 'US-CA',
    pdfFieldName: 'Client Abuse12'
  },
  {
    id: 'injury-from-behavior',
    name: 'Injury from Behavior Episode',
    group: 'General',
    stateId: 'US-CA',
    pdfFieldName: 'Client Abuse13'
  },
  {
    id: 'epidemic-outbreak',
    name: 'Epidemic Outbreak',
    group: 'General',
    stateId: 'US-CA',
    pdfFieldName: 'Client Abuse14'
  },
  {
    id: 'hospitalization',
    name: 'Hospitalization',
    group: 'General',
    stateId: 'US-CA',
    pdfFieldName: 'Client Abuse15'
  },
  {
    id: 'medical-emergency',
    name: 'Medical Emergency',
    group: 'General',
    stateId: 'US-CA',
    pdfFieldName: 'Client Abuse16'
  },
  {
    id: 'other-sexual-incident',
    name: 'Other Sexual Incident',
    group: 'General',
    stateId: 'US-CA',
    pdfFieldName: 'Client Abuse17'
  },
  {
    id: 'theft',
    name: 'Theft',
    group: 'General',
    stateId: 'US-CA',
    pdfFieldName: 'Client Abuse18'
  },
  {
    id: 'fire',
    name: 'Fire',
    group: 'General',
    stateId: 'US-CA',
    pdfFieldName: 'Client Abuse19'
  },
  {
    id: 'property-damage',
    name: 'Property Damage',
    group: 'General',
    stateId: 'US-CA',
    pdfFieldName: 'Client Abuse20'
  },
  {
    id: 'other',
    name: 'Other',
    group: 'General',
    stateId: 'US-CA',
    pdfFieldName: 'Client Abuse21'
  }
] as const;

export const incidentTypeIds = incidentTypes.map(({ id }) => id);

export type IncidentTypeId = (typeof incidentTypeIds)[number];

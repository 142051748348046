export const medicalProfessionalTypes = [
  {
    id: 'md',
    name: 'Physician'
  },
  {
    id: 'pa',
    name: "Physician's Assistant"
  },
  {
    id: 'np',
    name: 'Nurse Practitioner'
  },
  {
    id: 'rn',
    name: 'Registered Nurse'
  },
  {
    id: 'lvn',
    name: 'Licensed Vocational Nurse'
  },
  {
    id: 'ot',
    name: 'Occupational Therapist'
  },
  {
    id: 'pt',
    name: 'Physical Therapist'
  },
  {
    id: 'slp',
    name: 'Speech Therapist'
  },
  {
    id: 'rt',
    name: 'Respiratory Therapist'
  },
  {
    id: 'dds',
    name: 'Dentist'
  },
  {
    id: 'od',
    name: 'Optometrist'
  },
  {
    id: 'other',
    name: 'Other'
  }
] as const;

export const medicalProfessionalTypeIds = medicalProfessionalTypes.map(
  ({ id }) => id
);

export type MedicalProfessionalTypeId =
  (typeof medicalProfessionalTypeIds)[number];

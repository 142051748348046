export const employmentStatuses = [
  {
    id: 'active',
    name: 'Active'
  },
  {
    id: 'terminated',
    name: 'Terminated'
  },
  {
    id: 'quit',
    name: 'Quit'
  },
  {
    id: 'leave',
    name: 'On Leave'
  },
  {
    id: 'resigned',
    name: 'Resigned'
  }
] as const;

export const employmentStatusIds = employmentStatuses.map(({ id }) => id);

export type EmploymentStatusId = (typeof employmentStatusIds)[number];

export const medicationMovementTypes = [
  {
    id: 'add',
    name: 'Add',
    description: 'When a new medication inventory is created'
  },
  {
    id: 'pop',
    name: 'Pop',
    description: 'When a new pop action is made for a medication'
  },
  {
    id: 'pass',
    name: 'Pass',
    description: 'When a new pass action is made for a medication'
  },
  {
    id: 'unpop',
    name: 'Unpop',
    description: 'When a new unpop action is made for a medication'
  },
  {
    id: 'unpass',
    name: 'Unpass',
    description: 'When a new unpass action is made for a medication'
  },
  {
    id: 'popOmitted',
    name: 'Pop omitted',
    description:
      'When a new omit action is made for a medication when the previous state was pop'
  },
  {
    id: 'passOmitted',
    name: 'Pass omitted',
    description:
      'When a new omit action is made for a medication when the previous state was pass'
  },
  {
    id: 'adjustment',
    name: 'Adjustment',
    description: 'When a inventory adjustment is made'
  },
  {
    id: 'omitted',
    name: 'Omitted',
    description: 'When a new omit action is made for a medication'
  },
  {
    id: 'unomit',
    name: 'Un Omitted',
    description: 'When a direct omit medication was made'
  },
  {
    id: 'unpopOmitted',
    name: 'Unpop Omitted',
    description: 'When undoing an pop omitted action'
  },
  {
    id: 'unpassOmitted',
    name: 'Unpass Omitted',
    description: 'When undoing an pass omitted action'
  }
] as const;

export const medicationMovementTypeIds = medicationMovementTypes.map(
  ({ id }) => id
);

export type MedicationMovementTypeId =
  (typeof medicationMovementTypeIds)[number];

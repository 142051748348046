export const positions = [
  {
    id: 'sit',
    name: 'Sitting'
  },
  {
    id: 'recline',
    name: 'Reclining'
  },
  {
    id: 'lie',
    name: 'Lying'
  }
] as const;

export const positionIds = positions.map(({ id }) => id);

export type PositionId = (typeof positionIds)[number];

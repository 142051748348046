export const incidentReportStatuses = [
  {
    id: 'new',
    name: 'New',
    icon: 'assets/icon/material/clipboard_alert_outline.svg',
    class: 'bg-sky-200 hover:bg-sky-200 text-sky-800',
    badgeClass: 'bg-sky-200 text-sky-800'
  },
  {
    id: 'investigating',
    name: 'Investigating',
    icon: 'assets/icon/material/search.svg',
    class: 'bg-violet-200 hover:bg-violet-200 text-violet-800',
    badgeClass: 'bg-violet-200 text-violet-800'
  },
  {
    id: 'complete',
    name: 'Complete',
    icon: 'assets/icon/material/task_alt.svg',
    class: 'bg-green-200 hover:bg-green-200 text-green-800',
    badgeClass: 'bg-green-200 text-green-800'
  }
] as const;

export const incidentReportStatusIds = incidentReportStatuses.map(
  ({ id }) => id
);

export type IncidentReportStatusId = (typeof incidentReportStatusIds)[number];

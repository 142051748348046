export const measurements = [
  {
    id: 'ng',
    name: 'Nanogram',
    abbreviation: 'ng',
    plural: 'Nanograms',
    type: 'weight',
    alternatives: null
  },
  {
    id: 'mcg',
    name: 'Microgram',
    abbreviation: 'mcg',
    plural: 'Micrograms',
    type: 'weight',
    alternatives: ['mcg', 'microgram', 'micrograms']
  },
  {
    id: 'mg',
    name: 'Milligram',
    abbreviation: 'mg',
    plural: 'Milligrams',
    type: 'weight',
    alternatives: ['mg', 'milligram', 'milligrams']
  },
  {
    id: 'g',
    name: 'Gram',
    abbreviation: 'g',
    plural: 'Grams',
    type: 'weight',
    alternatives: ['g', 'gram', 'grams']
  },
  {
    id: 'kg',
    name: 'Kilogram',
    abbreviation: 'kg',
    plural: 'Kilograms',
    type: 'weight',
    alternatives: ['kg', 'kilogram', 'kilograms']
  },
  {
    id: 'oz',
    name: 'Ounce',
    abbreviation: 'oz',
    plural: 'Ounces',
    type: 'weight',
    alternatives: ['oz', 'ounce', 'ounces']
  },
  {
    id: 'lbs',
    name: 'Pound',
    abbreviation: 'lbs',
    plural: 'Pounds',
    type: 'weight',
    alternatives: ['lbs', 'pound', 'pounds']
  },
  {
    id: 'unit',
    name: 'Unit',
    abbreviation: 'units',
    plural: 'Units',
    type: 'weight',
    alternatives: ['unit', 'units']
  },
  {
    id: 'iu',
    name: 'International Unit',
    abbreviation: 'iu',
    plural: 'International Units',
    type: 'weight',
    alternatives: ['iu']
  },
  {
    id: 'unitgram',
    name: 'Unit Gram',
    abbreviation: 'Unit/Gram',
    plural: 'Unit Grams',
    type: 'weight',
    alternatives: [
      'unitgram',
      'unitgrams',
      'unitsgram',
      'unit gram',
      'unit grams',
      'units gram'
    ]
  },
  {
    id: 'ml',
    name: 'Milliliter',
    abbreviation: 'mL',
    plural: 'Milliliters',
    type: 'volume',
    alternatives: ['ml', 'milliliter', 'milliliters']
  },
  {
    id: 'mgml',
    name: 'Milligram to Milliliter',
    abbreviation: 'mg/mL',
    plural: 'Milligrams to Milliliters',
    type: 'volume',
    alternatives: ['mgml']
  },
  {
    id: 'gml',
    name: 'Gram to Milliliter',
    abbreviation: 'g/mL',
    plural: 'Grams to Milliliters',
    type: 'volume',
    alternatives: ['gml']
  },
  {
    id: 'unitml',
    name: 'Unit to Milliliter',
    abbreviation: 'units/mL',
    plural: 'Units to Milliliters',
    type: 'volume',
    alternatives: ['unitml', 'unitsml']
  },
  {
    id: 'l',
    name: 'Liter',
    abbreviation: 'L',
    plural: 'Liters',
    type: 'volume',
    alternatives: ['l', 'liter', 'liters']
  },
  {
    id: 'tsp',
    name: 'Teaspoon',
    abbreviation: 'tsp',
    plural: 'Teaspoons',
    type: 'volume',
    alternatives: [
      'tsp',
      'table spoon',
      'table spoons',
      'tablespoon',
      'tablespoons'
    ]
  },
  {
    id: 'tbsp',
    name: 'Tablespoon',
    abbreviation: 'Tbsp',
    plural: 'Tablespoons',
    type: 'volume',
    alternatives: [
      'tbsp',
      'table spoon',
      'table spoons',
      'tablespoon',
      'tablespoons'
    ]
  },
  {
    id: 'floz',
    name: 'Fluid Ounce',
    abbreviation: 'fl oz',
    plural: 'Fluid Ounces',
    type: 'volume',
    alternatives: ['floz', 'fl oz', 'fluid ounce', 'fluid ounces']
  },
  {
    id: 'lmin',
    name: 'Liters per Minute',
    abbreviation: 'L/min',
    plural: 'Liters per Minute',
    type: 'volume',
    alternatives: null
  },
  {
    id: 'pct',
    name: 'Percent',
    abbreviation: '%',
    plural: 'Percent',
    type: 'mixed',
    alternatives: ['pct', '%', 'percent']
  },
  {
    id: 'meq',
    name: 'Milliequivalent',
    abbreviation: 'mEq',
    plural: 'Milliequivalent',
    type: 'mixed',
    alternatives: null
  },
  {
    id: 'meql',
    name: 'Milliequivalent per Liter',
    abbreviation: 'mEq/L',
    plural: 'Milliequivalent per Liter',
    type: 'mixed',
    alternatives: null
  }
] as const;

export type Measurement = (typeof measurements)[number];

export const measurementIds = measurements.map(({ id }) => id);

export type MeasurementId = (typeof measurementIds)[number];

import { RruleConfig } from '../rrule.interface';

export const documentRenewalIntervals = [
  {
    id: 'never',
    name: 'Never',
    description: 'The document should not be renewed',
    frequency: 'never',
    period: 'never',
    rruleConfig: null
  },
  {
    id: 'annually',
    name: 'Annually',
    description: 'The document should be renewed annually (every year)',
    frequency: 'Every Year',
    period: '1 Year',
    rruleConfig: new RruleConfig({
      freq: 'yearly',
      interval: 1,
      // NOTE: When computing the next expiration date you have to set the start date. The first occurrence rrule creates is
      // for that start date, but that's not the date we want. We want the next occurrence after that. By setting the count to 2 and grabbing the
      // second occurrence we get the next occurrence after the start date.
      count: 2
    })
  },
  {
    id: 'semiannually',
    name: 'Semi-annually (Every 6 Months)',
    description:
      'The document should be renewed semi-annually (every 6 months)',
    frequency: 'Every 6 Months',
    period: '6 Months',
    rruleConfig: new RruleConfig({
      freq: 'monthly',
      interval: 6,
      count: 2
    })
  },
  {
    id: 'quarterly',
    name: 'Quarterly (Every 3 Months)',
    description: 'The document should be renewed quarterly (every 3 months)',
    frequency: 'Every 3 Months',
    period: '3 Months',
    rruleConfig: new RruleConfig({
      freq: 'monthly',
      interval: 3,
      count: 4
    })
  },
  {
    id: 'monthly',
    name: 'Monthly (Every Month)',
    description: 'The document should be renewed monthly (every month)',
    frequency: 'Every Month',
    period: '1 Month',
    rruleConfig: new RruleConfig({
      freq: 'monthly',
      interval: 1,
      count: 12
    })
  },
  {
    id: 'numDays',
    name: 'A specific number of days',
    description: 'The document should be renewed in a specific number of days',
    frequency: null,
    rruleConfig: null
  }
] as const;

export const documentRenewalIntervalIds = documentRenewalIntervals.map(
  ({ id }) => id
);

export type DocumentRenewalIntervalId =
  (typeof documentRenewalIntervalIds)[number];

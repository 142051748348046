export const carePlanIssuers = [
  {
    id: 'mp',
    name: 'Medical Professional'
  },
  {
    id: 'fam',
    name: 'Family'
  },
  {
    id: 'fac',
    name: 'Facility'
  },
  {
    id: 'lic',
    name: 'Licensing'
  }
] as const;

export const carePlanIssuerIds = carePlanIssuers.map(({ id }) => id);

export type CarePlanIssuerId = (typeof carePlanIssuerIds)[number];

export const dnrStatuses = [
  {
    id: 'none',
    name: 'No DNR/POLST',
    status: 'NO DNR'
  },
  {
    id: 'dnr',
    name: 'DNR',
    status: 'DNR'
  },
  {
    id: 'polst',
    name: 'POLST',
    status: 'POLST'
  },
  {
    id: 'fullCode',
    name: 'Full Code',
    status: 'Full Code'
  }
] as const;

export const dnrStatusIds = dnrStatuses.map(({ id }) => id);

export type DNRStatusId = (typeof dnrStatusIds)[number];

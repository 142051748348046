export const medicationMarStates = [
  {
    id: 'pop',
    name: 'Popped'
  },
  {
    id: 'pass',
    name: 'Passed'
  },
  {
    id: 'omit',
    name: 'Omitted'
  }
] as const;

export const medicationMarStateIds = medicationMarStates.map(({ id }) => id);

export type MedicationMarStateId = (typeof medicationMarStateIds)[number];

export const bodyAbnormalities = [
  {
    id: 'abrasion',
    name: 'Abrasion'
  },
  {
    id: 'blister',
    name: 'Blister'
  },
  {
    id: 'bruise',
    name: 'Bruise'
  },
  {
    id: 'bumps',
    name: 'Bumps'
  },
  {
    id: 'burn',
    name: 'Burn'
  },
  {
    id: 'cut',
    name: 'Cut'
  },
  {
    id: 'hives',
    name: 'Hives'
  },
  {
    id: 'rash',
    name: 'Rash'
  },
  {
    id: 'scratch',
    name: 'Scratch'
  },
  {
    id: 'skin-tear',
    name: 'Skin Tear'
  },
  {
    id: 'swollen',
    name: 'Swollen/Swelling'
  },
  {
    id: 'ulcer',
    name: 'Ulcer'
  },
  {
    id: 'wart',
    name: 'Wart'
  }
] as const;

export const bodyAbnormalityIds = bodyAbnormalities.map(({ id }) => id);

export type BodyAbnormalityId = (typeof bodyAbnormalityIds)[number];

export const foodRequirements = [
  {
    id: 'none',
    name: 'None'
  },
  {
    id: 'before',
    name: 'Before Meal'
  },
  {
    id: 'with',
    name: 'With Meal'
  }
] as const;

export const foodRequirementIds = foodRequirements.map(({ id }) => id);

export type FoodRequirementId = (typeof foodRequirementIds)[number];

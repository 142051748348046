export const documentLevels = [
  {
    id: 'org',
    name: 'Organization'
  },
  {
    id: 'fac',
    name: 'Facility'
  },
  {
    id: 'res',
    name: 'Resident'
  },
  {
    id: 'emp',
    name: 'Employee'
  }
] as const;

export const documentLevelIds = documentLevels.map(({ id }) => id);

export type DocumentLevelId = (typeof documentLevelIds)[number];

export const amountTypes = [
  {
    id: 'qty',
    name: 'Quantity',
    description:
      'Amount is expressed using the combination of qty (i.e. number of units) and strength. When giving medication, qty and strength makes up the dose. When managing inventory items, qty and strength makes up the total amount of medication in that inventory item.'
  },
  {
    id: 'total',
    name: 'Total',
    description:
      'Amount is expressed using the total unit of measurement (e.g. 100mg, 5ml, etc). When giving medication this is the same as the dose. When managing inventory items, this is the total amount of medication, in units of measurement (e.g. mg, ml, etc), in that inventory item.'
  }
] as const;

export const amountTypeIds = amountTypes.map(({ id }) => id);

export type AmountTypeId = (typeof amountTypeIds)[number];

export const residentStages = [
  {
    id: 'residentProspect',
    name: 'Prospect',
    description: 'A potential resident (pre assessment)',
    entityType: 'resident'
  },
  {
    id: 'residentPrePlacement',
    name: 'Pre-placement',
    description: 'A potential resident currently being assessed for fit',
    entityType: 'resident'
  },
  {
    id: 'residentAdmission',
    name: 'Admission',
    description: 'A new resident currently in the admission phase',
    entityType: 'resident'
  },
  {
    id: 'residentResident',
    name: 'Resident',
    description: 'A current active resident. (Post admission)',
    entityType: 'resident'
  },
  {
    id: 'residentLost',
    name: 'Lost',
    description:
      'A prospective client that was not placed for whatever reason.',
    entityType: 'resident'
  }
] as const;

export const residentStageIds = residentStages.map(({ id }) => id);

export type ResidentStageId = (typeof residentStageIds)[number];

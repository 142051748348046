export const medicationDestructionStatuses = [
  {
    id: 'details-needed',
    name: 'Details Needed'
  },
  {
    id: 'ready-for-destruction',
    name: 'Ready for Destruction'
  },
  {
    id: 'waiting-for-witness',
    name: 'Waiting for Witness'
  },
  {
    id: 'destroyed',
    name: 'Destroyed'
  }
] as const;

export const medicationDestructionStatusIds = medicationDestructionStatuses.map(
  ({ id }) => id
);

export type MedicationDestructionStatusId =
  (typeof medicationDestructionStatusIds)[number];

export const mobilityStatuses = [
  {
    id: 'ambulatory',
    name: 'Walks unassisted',
    description: 'Walks without assistance or mechanical device',
    icon: 'directions_walk'
  },
  {
    id: 'nonAmbulatoryAssistance',
    name: 'Requires assistance to walk',
    description: 'Walks with the assistance of a person',
    icon: 'wheelchair_pickup'
  },
  {
    id: 'nonAmbulatoryCane',
    name: 'Requires cane to walk',
    description: 'Walks with the assistance of a cane',
    icon: 'elderly'
  },
  {
    id: 'nonAmbulatoryWalker',
    name: 'Requires walker to walk',
    description: 'Walks with the assistance of a walker',
    icon: 'assist_walker'
  },
  {
    id: 'nonAmbulatoryWheelchair',
    name: 'Requires wheelchair',
    description: 'Cannot walk unassisted. Must use a wheelchair',
    icon: 'accessible'
  },
  {
    id: 'nonAmbulatoryBedriddenPartial',
    name: 'Bedridden (can turn self)',
    description: 'Bedridden, but can turn self.',
    icon: 'airline_seat_flat_angled'
  },
  {
    id: 'nonAmbulatoryBedriddenFull',
    name: 'Bedridden (cannot turn self)',
    description: 'Bedridden and cannot turn self.',
    icon: 'hotel'
  }
] as const;

export const mobilityStatusIds = mobilityStatuses.map(({ id }) => id);

export type MobilityStatusId = (typeof mobilityStatusIds)[number];

export const genders = [
  {
    id: 'male',
    name: 'Male'
  },
  {
    id: 'female',
    name: 'Female'
  }
] as const;

export const genderIds = genders.map(({ id }) => id);

export type GenderId = (typeof genderIds)[number];

export const severityLevels = [
  {
    id: 1,
    name: 'Negligible',
    rating: 0
  },
  {
    id: 2,
    name: 'Minor',
    rating: 1
  },
  {
    id: 3,
    name: 'Moderate',
    rating: 2
  },
  {
    id: 4,
    name: 'Serious',
    rating: 3
  },
  {
    id: 5,
    name: 'Severe',
    rating: 4
  }
] as const;

export const severityLevelIds = severityLevels.map(({ id }) => id);

export type SeverityLevelId = (typeof severityLevelIds)[number];

export const residentStatuses = [
  {
    id: 'active',
    name: 'Active'
  },
  {
    id: 'deceased',
    name: 'Deceased'
  },
  {
    id: 'discharged',
    name: 'Discharged'
  }
] as const;

export const residentStatusIds = residentStatuses.map(({ id }) => id);

export type ResidentStatusId = (typeof residentStatusIds)[number];

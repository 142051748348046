export const documentTemplatePacketCreationRules = [
  {
    id: 'always',
    name: 'Everyone',
    description: 'The packet will be created automatically for everyone',
    scopes: ['res', 'emp', 'fac', 'org']
  },
  {
    id: 'optional',
    name: 'Nobody (Optional)',
    description: 'The packet will be created manually, as needed (ad hoc).',
    scopes: ['res', 'emp', 'fac', 'org']
  },
  {
    id: 'residentHospice',
    name: 'Residents on Hospice',
    description:
      'The packet will be created automatically for residents who are on hospice.',
    scopes: ['res']
  },
  {
    id: 'residentHomeHealth',
    name: 'Residents on Home Health',
    description:
      'The packet will be created automatically for residents on home health.',
    scopes: ['res']
  }
] as const;

export const documentTemplatePacketCreationRuleIds =
  documentTemplatePacketCreationRules.map(({ id }) => id);

export type DocumentTemplatePacketCreationRuleId =
  (typeof documentTemplatePacketCreationRuleIds)[number];

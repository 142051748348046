export const pharmacyOrderingMethods = [
  {
    id: 'fax',
    name: 'Fax',
    description: 'Send order information via fax.'
  },
  {
    id: 'email',
    name: 'Email',
    description: 'Send order information via email.'
  }
] as const;

export const pharmacyOrderingMethodIds = pharmacyOrderingMethods.map(
  ({ id }) => id
);

export type PharmacyOrderingMethodId =
  (typeof pharmacyOrderingMethodIds)[number];

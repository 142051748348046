export const continenceStatuses = [
  {
    id: 'continent',
    name: 'Continent',
    description: 'Has full voluntary control over urination and defecation.'
  },
  {
    id: 'incontinentUrine',
    name: 'Incontinent of Urine',
    description: 'Has no or insufficient voluntary control over urination.'
  },
  {
    id: 'incontinentBowels',
    name: 'Incontinent of Bowels',
    description: 'Has no or insufficient voluntary control over defecation.'
  },
  {
    id: 'incontinentBoth',
    name: 'Incontinent of Urine & Bowels',
    description:
      'Has no or insufficient voluntary control over urination and defecation.'
  }
] as const;

export const continenceStatusIds = continenceStatuses.map(({ id }) => id);

export type ContinenceStatusId = (typeof continenceStatusIds)[number];

export const bmSizes = [
  {
    id: 'no',
    name: 'None'
  },
  {
    id: 'sm',
    name: 'Small'
  },
  {
    id: 'md',
    name: 'Medium'
  },
  {
    id: 'lg',
    name: 'Large'
  }
] as const;

export const bmSizeIds = bmSizes.map(({ id }) => id);

export type BmSizeId = (typeof bmSizeIds)[number];

export const bodyParts = [
  {
    id: 'head',
    name: 'Head',
    frontal: null,
    lateral: null
  },
  {
    id: 'forehead',
    name: 'Forehead',
    frontal: 'front',
    lateral: null
  },
  {
    id: 'eye',
    name: 'Eye',
    frontal: 'front',
    lateral: null
  },
  {
    id: 'ear',
    name: 'Ear',
    frontal: null,
    lateral: null
  },
  {
    id: 'mouth',
    name: 'Mouth',
    frontal: 'front',
    lateral: null
  },
  {
    id: 'nose',
    name: 'Nose',
    frontal: 'front',
    lateral: null
  },
  {
    id: 'cheek',
    name: 'Cheek',
    frontal: 'front',
    lateral: null
  },
  {
    id: 'jaw',
    name: 'Jaw',
    frontal: 'front',
    lateral: null
  },
  {
    id: 'chin',
    name: 'Chin',
    frontal: 'front',
    lateral: null
  },
  {
    id: 'neck',
    name: 'Neck',
    frontal: null,
    lateral: null
  },
  {
    id: 'shoulder',
    name: 'Shoulder',
    frontal: null,
    lateral: null
  },
  {
    id: 'upper-arm',
    name: 'Upper Arm',
    frontal: null,
    lateral: null
  },
  {
    id: 'elbow',
    name: 'Elbow',
    frontal: null,
    lateral: null
  },
  {
    id: 'forearm',
    name: 'Forearm',
    frontal: null,
    lateral: null
  },
  {
    id: 'wrist',
    name: 'Wrist',
    frontal: null,
    lateral: null
  },
  {
    id: 'hand',
    name: 'Hand',
    frontal: 'back',
    lateral: null
  },
  {
    id: 'palm',
    name: 'Hand (Palm)',
    frontal: 'front',
    lateral: null
  },
  {
    id: 'thumb',
    name: 'Thumb',
    frontal: null,
    lateral: null
  },
  {
    id: 'index-finger',
    name: 'Index Finger',
    frontal: null,
    lateral: null
  },
  {
    id: 'middle-finger',
    name: 'Middle Finger',
    frontal: null,
    lateral: null
  },
  {
    id: 'ring-finger',
    name: 'Ring Finger',
    frontal: null,
    lateral: null
  },
  {
    id: 'pinky-finger',
    name: 'Pinky Finger',
    frontal: null,
    lateral: null
  },
  {
    id: 'chest',
    name: 'Chest',
    frontal: 'front',
    lateral: null
  },
  {
    id: 'pec-breast',
    name: 'Pec/Breast',
    frontal: 'front',
    lateral: null
  },
  {
    id: 'ribs',
    name: 'Ribs',
    frontal: null,
    lateral: null
  },
  {
    id: 'upper-abdomen',
    name: 'Upper Abdomen',
    frontal: 'front',
    lateral: null
  },
  {
    id: 'lower-abdomen',
    name: 'Lower Abdomen',
    frontal: 'front',
    lateral: null
  },
  {
    id: 'hip',
    name: 'Hip',
    frontal: null,
    lateral: null
  },
  {
    id: 'pelvis',
    name: 'Pelvis',
    frontal: 'front',
    lateral: null
  },
  {
    id: 'groin',
    name: 'Groin',
    frontal: 'front',
    lateral: null
  },
  {
    id: 'upper-back',
    name: 'Upper Back',
    frontal: 'back',
    lateral: null
  },
  {
    id: 'shoulder-blade',
    name: 'Shoulder Blade',
    frontal: 'back',
    lateral: null
  },
  {
    id: 'mid-back',
    name: 'Mid Back',
    frontal: 'back',
    lateral: null
  },
  {
    id: 'lower-back',
    name: 'Lower Back',
    frontal: 'back',
    lateral: null
  },
  {
    id: 'buttocks',
    name: 'Buttocks',
    frontal: 'back',
    lateral: null
  },
  {
    id: 'upper-thigh',
    name: 'Upper Thigh',
    frontal: null,
    lateral: null
  },
  {
    id: 'mid-thigh',
    name: 'Mid Thigh',
    frontal: null,
    lateral: null
  },
  {
    id: 'lower-thigh',
    name: 'Lower Thigh',
    frontal: null,
    lateral: null
  },
  {
    id: 'knee',
    name: 'Knee',
    frontal: null,
    lateral: null
  },
  {
    id: 'upper-shin',
    name: 'Upper Shin',
    frontal: 'front',
    lateral: null
  },
  {
    id: 'mid-shin',
    name: 'Mid Shin',
    frontal: 'front',
    lateral: null
  },
  {
    id: 'lower-shin',
    name: 'Lower Shin',
    frontal: 'front',
    lateral: null
  },
  {
    id: 'upper-calf',
    name: 'Upper Calf',
    frontal: 'back',
    lateral: null
  },
  {
    id: 'mid-calf',
    name: 'Mid Calf',
    frontal: 'back',
    lateral: null
  },
  {
    id: 'lower-calf',
    name: 'Lower Calf',
    frontal: 'back',
    lateral: null
  },
  {
    id: 'ankle',
    name: 'Ankle',
    frontal: null,
    lateral: null
  },
  {
    id: 'foot',
    name: 'Foot',
    frontal: null,
    lateral: null
  },
  {
    id: 'heel',
    name: 'Heel',
    frontal: 'back',
    lateral: null
  },
  {
    id: 'big-toe',
    name: 'Big Toe',
    frontal: null,
    lateral: null
  },
  {
    id: 'long-toe',
    name: 'Long Toe',
    frontal: null,
    lateral: null
  },
  {
    id: 'middle-toe',
    name: 'Middle Toe',
    frontal: null,
    lateral: null
  },
  {
    id: 'ring-toe',
    name: 'Ring Toe',
    frontal: null,
    lateral: null
  },
  {
    id: 'pinky-toe',
    name: 'Pinky Toe',
    frontal: null,
    lateral: null
  }
] as const;

export const bodyPartIds = bodyParts.map(({ id }) => id);

export type BodyPartId = (typeof bodyPartIds)[number];

export const countries = [
  {
    id: 'US',
    name: 'United States'
  }
] as const;

export const countryIds = countries.map(({ id }) => id);

export type CountryId = (typeof countryIds)[number];

export const states = [
  {
    id: 'US-AL',
    country: 'US',
    name: 'Alabama',
    abbreviation: 'AL'
  },
  {
    id: 'US-AK',
    country: 'US',
    name: 'Alaska',
    abbreviation: 'AK'
  },
  {
    id: 'US-AZ',
    country: 'US',
    name: 'Arizona',
    abbreviation: 'AZ'
  },
  {
    id: 'US-AR',
    country: 'US',
    name: 'Arkansas',
    abbreviation: 'AR'
  },
  {
    id: 'US-CA',
    country: 'US',
    name: 'California',
    abbreviation: 'CA'
  },
  {
    id: 'US-CO',
    country: 'US',
    name: 'Colorado',
    abbreviation: 'CO'
  },
  {
    id: 'US-CT',
    country: 'US',
    name: 'Connecticut',
    abbreviation: 'CT'
  },
  {
    id: 'US-DE',
    country: 'US',
    name: 'Delaware',
    abbreviation: 'DE'
  },
  {
    id: 'US-FL',
    country: 'US',
    name: 'Florida',
    abbreviation: 'FL'
  },
  {
    id: 'US-GA',
    country: 'US',
    name: 'Georgia',
    abbreviation: 'GA'
  },
  {
    id: 'US-HI',
    country: 'US',
    name: 'Hawaii',
    abbreviation: 'HI'
  },
  {
    id: 'US-ID',
    country: 'US',
    name: 'Idaho',
    abbreviation: 'ID'
  },
  {
    id: 'US-IL',
    country: 'US',
    name: 'Illinois',
    abbreviation: 'IL'
  },
  {
    id: 'US-IN',
    country: 'US',
    name: 'Indiana',
    abbreviation: 'IN'
  },
  {
    id: 'US-IA',
    country: 'US',
    name: 'Iowa',
    abbreviation: 'IA'
  },
  {
    id: 'US-KS',
    country: 'US',
    name: 'Kansas',
    abbreviation: 'KS'
  },
  {
    id: 'US-KY',
    country: 'US',
    name: 'Kentucky',
    abbreviation: 'KY'
  },
  {
    id: 'US-LA',
    country: 'US',
    name: 'Louisiana',
    abbreviation: 'LA'
  },
  {
    id: 'US-ME',
    country: 'US',
    name: 'Maine',
    abbreviation: 'ME'
  },
  {
    id: 'US-MD',
    country: 'US',
    name: 'Maryland',
    abbreviation: 'MD'
  },
  {
    id: 'US-MA',
    country: 'US',
    name: 'Massachusetts',
    abbreviation: 'MA'
  },
  {
    id: 'US-MI',
    country: 'US',
    name: 'Michigan',
    abbreviation: 'MI'
  },
  {
    id: 'US-MN',
    country: 'US',
    name: 'Minnesota',
    abbreviation: 'MN'
  },
  {
    id: 'US-MS',
    country: 'US',
    name: 'Mississippi',
    abbreviation: 'MS'
  },
  {
    id: 'US-MO',
    country: 'US',
    name: 'Missouri',
    abbreviation: 'MO'
  },
  {
    id: 'US-MT',
    country: 'US',
    name: 'Montana',
    abbreviation: 'MT'
  },
  {
    id: 'US-NE',
    country: 'US',
    name: 'Nebraska',
    abbreviation: 'NE'
  },
  {
    id: 'US-NV',
    country: 'US',
    name: 'Nevada',
    abbreviation: 'NV'
  },
  {
    id: 'US-NH',
    country: 'US',
    name: 'New Hampshire',
    abbreviation: 'NH'
  },
  {
    id: 'US-NJ',
    country: 'US',
    name: 'New Jersey',
    abbreviation: 'NJ'
  },
  {
    id: 'US-NM',
    country: 'US',
    name: 'New Mexico',
    abbreviation: 'NM'
  },
  {
    id: 'US-NY',
    country: 'US',
    name: 'New York',
    abbreviation: 'NY'
  },
  {
    id: 'US-NC',
    country: 'US',
    name: 'North Carolina',
    abbreviation: 'NC'
  },
  {
    id: 'US-ND',
    country: 'US',
    name: 'North Dakota',
    abbreviation: 'ND'
  },
  {
    id: 'US-OH',
    country: 'US',
    name: 'Ohio',
    abbreviation: 'OH'
  },
  {
    id: 'US-OK',
    country: 'US',
    name: 'Oklahoma',
    abbreviation: 'OK'
  },
  {
    id: 'US-OR',
    country: 'US',
    name: 'Oregon',
    abbreviation: 'OR'
  },
  {
    id: 'US-PA',
    country: 'US',
    name: 'Pennsylvania',
    abbreviation: 'PA'
  },
  {
    id: 'US-RI',
    country: 'US',
    name: 'Rhode Island',
    abbreviation: 'RI'
  },
  {
    id: 'US-SC',
    country: 'US',
    name: 'South Carolina',
    abbreviation: 'SC'
  },
  {
    id: 'US-SD',
    country: 'US',
    name: 'South Dakota',
    abbreviation: 'SD'
  },
  {
    id: 'US-TN',
    country: 'US',
    name: 'Tennessee',
    abbreviation: 'TN'
  },
  {
    id: 'US-TX',
    country: 'US',
    name: 'Texas',
    abbreviation: 'TX'
  },
  {
    id: 'US-UT',
    country: 'US',
    name: 'Utah',
    abbreviation: 'UT'
  },
  {
    id: 'US-VT',
    country: 'US',
    name: 'Vermont',
    abbreviation: 'VT'
  },
  {
    id: 'US-VA',
    country: 'US',
    name: 'Virginia',
    abbreviation: 'VA'
  },
  {
    id: 'US-WA',
    country: 'US',
    name: 'Washington',
    abbreviation: 'WA'
  },
  {
    id: 'US-WV',
    country: 'US',
    name: 'West Virginia',
    abbreviation: 'WV'
  },
  {
    id: 'US-WI',
    country: 'US',
    name: 'Wisconsin',
    abbreviation: 'WI'
  },
  {
    id: 'US-WY',
    country: 'US',
    name: 'Wyoming',
    abbreviation: 'WY'
  },
  {
    id: 'US-DC',
    country: 'US',
    name: 'Washington DC',
    abbreviation: 'DC'
  }
] as const;

export const stateIds = states.map(({ id }) => id);

export type StateId = (typeof stateIds)[number];

export const documentRequirements = [
  {
    id: 'always',
    name: 'Everyone',
    description: 'The document will be created automatically for everyone.',
    scopes: ['res', 'emp', 'fac', 'org']
  },
  {
    id: 'optional',
    name: 'Nobody (Optional)',
    description: 'The document will be created manually, as needed (ad hoc).',
    scopes: ['res', 'emp', 'fac', 'org']
  },
  {
    id: 'residentPrn',
    name: 'Residents with PRNs',
    description:
      'The document will be created automatically for residents with PRN medications.',
    scopes: ['res']
  },
  {
    id: 'residentHospice',
    name: 'Residents on Hospice',
    description:
      'The document will be created automatically for residents who are on hospice.',
    scopes: ['res']
  },
  {
    id: 'residentBedridden',
    name: 'Residents who are bedridden',
    description:
      'The document will be created automatically for residents who are bedridden.',
    scopes: ['res']
  },
  {
    id: 'residentIncontinent',
    name: 'Residents who are incontinent',
    description:
      'The document will be created automatically for residents who are incontinent.',
    scopes: ['res']
  },
  {
    id: 'residentDeceased',
    name: 'Residents who are Deceased',
    description:
      'The document will be created automatically for residents who are deceased.',
    scopes: ['res']
  }
] as const;

export const documentRequirementIds = documentRequirements.map(({ id }) => id);

export type DocumentRequirementId = (typeof documentRequirementIds)[number];

export const moods = [
  {
    id: 'neutral',
    name: 'Neutral'
  },
  {
    id: 'happy',
    name: 'Happy'
  },
  {
    id: 'sad',
    name: 'Sad'
  },
  {
    id: 'irritated',
    name: 'Irritated'
  },
  {
    id: 'angry',
    name: 'Angry'
  },
  {
    id: 'depressed',
    name: 'Depressed'
  },
  {
    id: 'anxious',
    name: 'Anxious'
  },
  {
    id: 'agitated',
    name: 'Agitated'
  },
  {
    id: 'abusive',
    name: 'Abusive'
  },
  {
    id: 'confused',
    name: 'Confused'
  }
] as const;

export const moodIds = moods.map(({ id }) => id);

export type MoodId = (typeof moodIds)[number];

export const medicationOrderItemStatuses = [
  {
    id: 'pending',
    name: 'Pending'
  },
  {
    id: 'failed',
    name: 'Failed'
  },
  {
    id: 'filled',
    name: 'Filled'
  },
  {
    id: 'cancelled',
    name: 'Cancelled'
  },
  {
    id: 'received',
    name: 'Received'
  }
] as const;

export const medicationOrderItemStatusIds = medicationOrderItemStatuses.map(
  ({ id }) => id
);

export type MedicationOrderItemStatusId =
  (typeof medicationOrderItemStatusIds)[number];

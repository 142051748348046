export const medicationDestructionItemStatuses = [
  {
    id: 'details-needed',
    name: 'Details Needed'
  },
  {
    id: 'ready-for-destruction',
    name: 'Ready for Destruction'
  },
  {
    id: 'to-be-destroyed',
    name: 'To be Destroyed'
  },
  {
    id: 'destroyed',
    name: 'Destroyed'
  }
] as const;

export const medicationDestructionItemStatusIds =
  medicationDestructionItemStatuses.map(({ id }) => id);

export type MedicationDestructionItemStatusId =
  (typeof medicationDestructionItemStatusIds)[number];

export const eyeColors = [
  {
    id: 'brown',
    name: 'Brown'
  },
  {
    id: 'amber',
    name: 'Amber'
  },
  {
    id: 'green',
    name: 'Green'
  },
  {
    id: 'hazel',
    name: 'Hazel'
  },
  {
    id: 'blue',
    name: 'Blue'
  },
  {
    id: 'gray',
    name: 'Gray'
  },
  {
    id: 'other',
    name: 'Other'
  }
] as const;

export const eyeColorIds = eyeColors.map(({ id }) => id);

export type eyeColorId = (typeof eyeColorIds)[number];

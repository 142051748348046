export const medicationScheduleTypes = [
  {
    id: 'daily',
    name: 'Daily'
  },
  {
    id: 'weekdays',
    name: 'Weekdays'
  },
  {
    id: 'interval',
    name: 'Interval'
  }
] as const;

export const medicationScheduleTypeIds = medicationScheduleTypes.map(
  ({ id }) => id
);

export type MedicationScheduleTypeId =
  (typeof medicationScheduleTypeIds)[number];

export const heightUnits = [
  {
    id: 'in',
    abbreviation: 'in',
    name: 'Inch',
    namePlural: 'Inches',
    system: 'Imperial'
  },
  {
    id: 'cm',
    abbreviation: 'cm',
    name: 'Centimeter',
    namePlural: 'Centimeters',
    system: 'Metric'
  }
] as const;

export const heightUnitIds = heightUnits.map(({ id }) => id);

export type HeightUnitId = (typeof heightUnitIds)[number];

export const medicalEventTypes = [
  {
    id: 'hospital',
    name: 'Hospital Visit'
  },
  {
    id: 'emergency',
    name: 'Emergency Call'
  },
  {
    id: 'procedure',
    name: 'Medical Procedure'
  },
  {
    id: 'accident',
    name: 'Accident'
  },
  {
    id: 'visit',
    name: 'Medical Visit'
  }
] as const;

export const medicalEventTypeIds = medicalEventTypes.map(({ id }) => id);

export type MedicalEventTypeId = (typeof medicalEventTypeIds)[number];

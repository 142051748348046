export const doseTypes = [
  {
    id: 'value',
    name: 'Value',
    description:
      'Used when a dose is a single value. This is the most common dose type.'
  },
  {
    id: 'range',
    name: 'Range',
    description: 'Used when a dose is within a range of possibilities.'
  },
  {
    id: 'option',
    name: 'Option',
    description: 'Used when a dose can be one of multiple options'
  },
  {
    id: 'log',
    name: 'Log Dependency',
    description: 'Used when a dose depends on a value from a log record.'
  }
] as const;

export const doseTypeIds = doseTypes.map(({ id }) => id);

export type DoseTypeId = (typeof doseTypeIds)[number];

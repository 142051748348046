export const weightUnits = [
  {
    id: 'lbs',
    abbreviation: 'lbs',
    name: 'Pounds'
  },
  {
    id: 'kg',
    abbreviation: 'kg',
    name: 'Kilograms'
  }
] as const;

export const weightUnitIds = weightUnits.map(({ id }) => id);

export type WeightUnitId = (typeof weightUnitIds)[number];

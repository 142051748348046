export const urineMeasurements = [
  {
    id: 'ml',
    abbreviation: 'ml',
    name: 'Milliliters'
  },
  {
    id: 'cc',
    abbreviation: 'cc',
    name: 'Cubic Centimeters'
  }
] as const;

export const urineMeasurementIds = urineMeasurements.map(({ id }) => id);

export type UrineMeasurementId = (typeof urineMeasurementIds)[number];

export const documentStatuses = [
  {
    id: 'new',
    name: 'New'
  },
  {
    id: 'data',
    name: 'Pending Data Entry'
  },
  {
    id: 'review',
    name: 'Pending Review'
  },
  {
    id: 'signature',
    name: 'Pending Signature'
  },
  {
    id: 'complete',
    name: 'Complete'
  }
] as const;

export const DocumentStatusIds = documentStatuses.map(({ id }) => id);

export type DocumentStatusId = (typeof DocumentStatusIds)[number];

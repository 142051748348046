export const medicationTypes = [
  {
    id: 'antacid',
    name: 'Antacid'
  },
  {
    id: 'antiallergic',
    name: 'Anti-allergic'
  },
  {
    id: 'antiinflammatory',
    name: 'Anti-inflammatory'
  },
  {
    id: 'antibiotic',
    name: 'Antibiotic'
  },
  {
    id: 'antidepressant',
    name: 'Antidepressant'
  },
  {
    id: 'antidiabetic',
    name: 'Antidiabetic'
  },
  {
    id: 'antidiarrhoeal',
    name: 'Antidiarrhoeal'
  },
  {
    id: 'antifungal',
    name: 'Antifungal'
  },
  {
    id: 'antihistamine',
    name: 'Antihistamine'
  },
  {
    id: 'antihypertensive',
    name: 'Antihypertensive'
  },
  {
    id: 'antiparkinson',
    name: 'Antiparkinson'
  },
  {
    id: 'antipsychotic',
    name: 'Antipsychotic'
  },
  {
    id: 'birthcontrol',
    name: 'Birth Control'
  },
  {
    id: 'bloodpressure',
    name: 'Blood Pressure'
  },
  {
    id: 'bloodthinner',
    name: 'Blood Thinner'
  },
  {
    id: 'decongestant',
    name: 'Decongestant'
  },
  {
    id: 'hypnotic',
    name: 'Hypnotic'
  },
  {
    id: 'laxative',
    name: 'Laxative'
  },
  {
    id: 'memory',
    name: 'Memory'
  },
  {
    id: 'multivitamin',
    name: 'Multi Vitamin'
  },
  {
    id: 'muscle-relaxant',
    name: 'Muscle relaxant'
  },
  {
    id: 'other',
    name: 'Other'
  },
  {
    id: 'painkiller',
    name: 'Painkiller'
  },
  {
    id: 'probiotic',
    name: 'Probiotic'
  },
  {
    id: 'sedative',
    name: 'Sedative'
  },
  {
    id: 'vitamin',
    name: 'Vitamin'
  }
] as const;

export const medicationTypeIds = medicationTypes.map(({ id }) => id);

export type MedicationTypeId = (typeof medicationTypeIds)[number];

export const documentScheduleAnchors = [
  {
    id: 'documentCreation',
    name: 'Document Creation Date',
    description:
      'How often a document is renewed will be based on the date the document was created.',
    scopes: ['res', 'emp']
  },
  {
    id: 'documentCompletion',
    name: 'Document Completion Date',
    description:
      'How often a document is renewed will be based on the date the document was completed.',
    scopes: ['res', 'emp']
  },
  {
    id: 'residentAnniversary',
    name: 'Admission Date',
    description:
      'How often a document is renewed will be based on the date the resident was admitted',
    scopes: ['res']
  },
  {
    id: 'employeeAnniversary',
    name: 'Hire Date',
    description:
      'How often a document is renewed will be based on the date the employee was hired',
    scopes: ['emp']
  },
  {
    id: 'residentBirthDate',
    name: 'Birth Date',
    description:
      "How often a document is renewed will be based on the resident's date of birth",
    scopes: ['res']
  },
  {
    id: 'customDate',
    name: 'Custom Date',
    description:
      'How often a document is renewed will be based on a custom date that you specify',
    scopes: ['res', 'emp', 'fac', 'org']
  }
] as const;

export const documentScheduleAnchorIds = documentScheduleAnchors.map(
  ({ id }) => id
);

export type DocumentScheduleAnchorId =
  (typeof documentScheduleAnchorIds)[number];

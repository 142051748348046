export const pdfReports = [
  {
    id: 'resident-facesheet',
    name: 'Resident Facesheet'
  },
  {
    id: 'manual-med-sheet',
    name: 'Manual Med Sheet'
  },
  {
    id: 'simplified-medication-list',
    name: 'Simplified Medication List'
  },
  {
    id: 'mar-report',
    name: 'MAR Report'
  },
  {
    id: 'simplified-mar-report',
    name: 'Simplified MAR Report'
  },
  {
    id: 'centrally-stored-report',
    name: 'Centrally Stored Report'
  },
  {
    id: 'prn-report',
    name: 'PRN Report'
  },
  {
    id: 'care-task-report',
    name: 'Care Task Report'
  },
  {
    id: 'resident-logs',
    name: 'Resident Logs'
  },
  {
    id: 'resident-events',
    name: 'Resident Events'
  }
] as const;

export const pdfReportIds = pdfReports.map(({ id }) => id);

export type PdfReportId = (typeof pdfReportIds)[number];

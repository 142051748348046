export const careTaskFrequencyTypes = [
  {
    id: 'daily',
    name: 'Daily'
  },
  {
    id: 'weekdays',
    name: 'Weekdays'
  },
  {
    id: 'monthly',
    name: 'Monthly'
  }
] as const;

export const careTaskFrequencyTypeIds = careTaskFrequencyTypes.map(
  ({ id }) => id
);

export type CareTaskFrequencyTypeId = (typeof careTaskFrequencyTypeIds)[number];

export const documentParticipantTypes = [
  {
    id: 'facilityUsers',
    name: 'Facility Users Table'
  },
  {
    id: 'residentContacts',
    name: 'Resident Contacts Table'
  },
  {
    id: 'medicalProfessionals',
    name: 'Medical Professionals Table'
  }
] as const;

export const documentParticipantTypeIds = documentParticipantTypes.map(
  ({ id }) => id
);

export type DocumentParticipantTypeId =
  (typeof documentParticipantTypeIds)[number];

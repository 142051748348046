export const residentContactRelationships = [
  {
    id: 'son',
    name: 'Son'
  },
  {
    id: 'daughter',
    name: 'Daughter'
  },
  {
    id: 'brother',
    name: 'Brother'
  },
  {
    id: 'sister',
    name: 'Sister'
  },
  {
    id: 'mother',
    name: 'Mother'
  },
  {
    id: 'father',
    name: 'Father'
  },
  {
    id: 'grandson',
    name: 'Grandson'
  },
  {
    id: 'granddaughter',
    name: 'Granddaughter'
  },
  {
    id: 'nephew',
    name: 'Nephew'
  },
  {
    id: 'niece',
    name: 'Niece'
  },
  {
    id: 'friend',
    name: 'Friend'
  },
  {
    id: 'family',
    name: 'Family'
  },
  {
    id: 'spouse',
    name: 'Spouse'
  },
  {
    id: 'legal',
    name: 'Legal Guardian'
  },
  {
    id: 'other',
    name: 'Other'
  }
] as const;

export const residentContactRelationshipIds = residentContactRelationships.map(
  ({ id }) => id
);

export type ResidentContactRelationshipId =
  (typeof residentContactRelationshipIds)[number];

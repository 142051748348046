import { AmountTypeId } from './amount-types.lookup';
import { MeasurementId } from './measurements.lookup';

export type TMedicationForm = {
  id: string;
  name: string;
  measurementId: MeasurementId | null;
  trackInventory: boolean;
  amountTypeId: AmountTypeId;
  isCommon: boolean;
  icon: string;
};

export const medicationForms = [
  {
    id: 'capsule',
    name: 'Capsule',
    measurementId: 'mg',
    trackInventory: true,
    amountTypeId: 'qty',
    isCommon: true,
    icon: 'material/pill'
  },
  {
    id: 'chewable',
    name: 'Chewable',
    measurementId: 'mg',
    trackInventory: true,
    amountTypeId: 'qty',
    isCommon: false,
    icon: 'alcomy/tablets'
  },
  {
    id: 'cream',
    name: 'Cream',
    measurementId: 'pct',
    trackInventory: false,
    amountTypeId: 'total',
    isCommon: false,
    icon: 'alcomy/hand_mound'
  },
  {
    id: 'drops',
    name: 'Drops',
    measurementId: 'ml',
    trackInventory: false,
    amountTypeId: 'total',
    isCommon: false,
    icon: 'material/eyedropper'
  },
  {
    id: 'elixir',
    name: 'Elixir',
    measurementId: 'ml',
    trackInventory: false,
    amountTypeId: 'total',
    isCommon: false,
    icon: 'material/local_drink_outline'
  },
  {
    id: 'emulsion',
    name: 'Emulsion',
    measurementId: 'ml',
    trackInventory: false,
    amountTypeId: 'total',
    isCommon: false,
    icon: 'material/total_dissolved_solids'
  },
  {
    id: 'enema',
    name: 'Enema',
    measurementId: 'mg',
    trackInventory: false,
    amountTypeId: 'qty',
    isCommon: false,
    icon: 'alcomy/enema_bag'
  },
  {
    id: 'eyedrops',
    name: 'Eye Drops',
    measurementId: 'ml',
    trackInventory: false,
    amountTypeId: 'total',
    isCommon: false,
    icon: 'material/symptoms'
  },
  {
    id: 'film',
    name: 'Film',
    measurementId: 'mg',
    trackInventory: true,
    amountTypeId: 'qty',
    isCommon: false,
    icon: 'material/mirror_rectangle'
  },
  {
    id: 'gel',
    name: 'Gel',
    measurementId: 'pct',
    trackInventory: false,
    amountTypeId: 'total',
    isCommon: false,
    icon: 'material/liquid_spot'
  },
  {
    id: 'gummy',
    name: 'Gummy',
    measurementId: 'mg',
    trackInventory: true,
    amountTypeId: 'qty',
    isCommon: false,
    icon: 'alcomy/gummy'
  },
  {
    id: 'inhaler',
    name: 'Inhaler',
    measurementId: 'mg',
    trackInventory: false,
    amountTypeId: 'qty',
    isCommon: false,
    icon: 'alcomy/inhaler'
  },
  {
    id: 'injection',
    name: 'Injection',
    measurementId: 'ml',
    trackInventory: false,
    amountTypeId: 'total',
    isCommon: false,
    icon: 'material/syringe'
  },
  {
    id: 'liquid',
    name: 'Liquid',
    measurementId: 'ml',
    trackInventory: false,
    amountTypeId: 'total',
    isCommon: true,
    icon: 'material/water_medium'
  },
  {
    id: 'lotion',
    name: 'Lotion',
    measurementId: 'pct',
    trackInventory: false,
    amountTypeId: 'total',
    isCommon: false,
    icon: 'material/lotion'
  },
  {
    id: 'lozenge',
    name: 'Lozenge',
    measurementId: 'unit',
    trackInventory: false,
    amountTypeId: 'qty',
    isCommon: false,
    icon: 'material/rhombus_outline'
  },
  {
    id: 'nebulizer',
    name: 'Nebulizer',
    measurementId: 'mg',
    trackInventory: false,
    amountTypeId: 'total',
    isCommon: false,
    icon: 'alcomy/inhaler'
  },
  {
    id: 'ointment',
    name: 'Ointment',
    measurementId: 'pct',
    trackInventory: false,
    amountTypeId: 'total',
    isCommon: false,
    icon: 'alcomy/ointment_drop'
  },
  {
    id: 'packet',
    name: 'Packet',
    measurementId: 'mg',
    trackInventory: true,
    amountTypeId: 'qty',
    isCommon: false,
    icon: 'material/square_circle'
  },
  {
    id: 'patch',
    name: 'Patch',
    measurementId: 'mg',
    trackInventory: false,
    amountTypeId: 'qty',
    isCommon: false,
    icon: 'material/select_all'
  },
  {
    id: 'powder',
    name: 'Powder',
    measurementId: 'mg',
    trackInventory: false,
    amountTypeId: 'total',
    isCommon: false,
    icon: 'alcomy/powder'
  },
  {
    id: 'shampoo',
    name: 'Shampoo',
    measurementId: 'oz',
    trackInventory: false,
    amountTypeId: 'total',
    isCommon: false,
    icon: 'material/lotion_outline'
  },
  {
    id: 'softgel',
    name: 'Soft Gel',
    measurementId: null,
    trackInventory: true,
    amountTypeId: 'qty',
    isCommon: false,
    icon: 'alcomy/soft_gel'
  },
  {
    id: 'solution',
    name: 'Solution',
    measurementId: 'ml',
    trackInventory: false,
    amountTypeId: 'total',
    isCommon: false,
    icon: 'material/test_tube'
  },
  {
    id: 'spray',
    name: 'Spray',
    measurementId: 'ml',
    trackInventory: false,
    amountTypeId: 'total',
    isCommon: false,
    icon: 'material/spray'
  },
  {
    id: 'suppository',
    name: 'Suppository',
    measurementId: 'mg',
    trackInventory: true,
    amountTypeId: 'qty',
    isCommon: false,
    icon: 'alcomy/suppository'
  },
  {
    id: 'suspension',
    name: 'Suspension',
    measurementId: 'ml',
    trackInventory: true,
    amountTypeId: 'total',
    isCommon: false,
    icon: 'material/test_tube'
  },
  {
    id: 'syringe',
    name: 'Syringe',
    measurementId: 'ml',
    trackInventory: true,
    amountTypeId: 'qty',
    isCommon: false,
    icon: 'material/syringe'
  },
  {
    id: 'tablet',
    name: 'Tablet',
    measurementId: 'mg',
    trackInventory: true,
    amountTypeId: 'qty',
    isCommon: true,
    icon: 'alcomy/tablet'
  },
  {
    id: 'other',
    name: 'Other',
    measurementId: null,
    trackInventory: true,
    amountTypeId: 'total',
    isCommon: false,
    icon: 'material/pill'
  }
] as const satisfies TMedicationForm[];

export type MedicationForm = (typeof medicationForms)[number];

export const medicationFormIds = medicationForms.map(({ id }) => id);

export type MedicationFormId = (typeof medicationFormIds)[number];

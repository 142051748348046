import { MaybeArray } from './types/utility-types';

export interface IRruleConfig {
  dtstart?: string | Date;
  until?: string | Date;
  freq?: 'yearly' | 'monthly' | 'weekly' | 'daily';
  interval?: number;
  count?: number;
  wkst?: 'su' | 'mo' | 'tu' | 'we' | 'th' | 'fr' | 'sa';

  byWeekday?: {
    su?: boolean;
    mo?: boolean;
    tu?: boolean;
    we?: boolean;
    th?: boolean;
    fr?: boolean;
    sa?: boolean;
  };

  byMonth?: {
    jan?: boolean;
    feb?: boolean;
    mar?: boolean;
    apr?: boolean;
    may?: boolean;
    jun?: boolean;
    jul?: boolean;
    aug?: boolean;
    sep?: boolean;
    oct?: boolean;
    nov?: boolean;
    dec?: boolean;
  };

  bySetPos?: MaybeArray<number>;
  byMonthDay?: MaybeArray<number>;
  byWeekNo?: MaybeArray<number>;
  times?: { time: string }[];
}

export class RruleConfig implements IRruleConfig {
  dtstart?: string | Date;
  until?: string | Date;
  freq?: 'yearly' | 'monthly' | 'weekly' | 'daily';
  interval?: number;
  count?: number;
  wkst?: 'su' | 'mo' | 'tu' | 'we' | 'th' | 'fr' | 'sa';

  byWeekday?: {
    su?: boolean;
    mo?: boolean;
    tu?: boolean;
    we?: boolean;
    th?: boolean;
    fr?: boolean;
    sa?: boolean;
  };

  byMonth?: {
    jan?: boolean;
    feb?: boolean;
    mar?: boolean;
    apr?: boolean;
    may?: boolean;
    jun?: boolean;
    jul?: boolean;
    aug?: boolean;
    sep?: boolean;
    oct?: boolean;
    nov?: boolean;
    dec?: boolean;
  };

  bySetPos?: MaybeArray<number>;
  byMonthDay?: MaybeArray<number>;
  byWeekNo?: MaybeArray<number>;
  times?: { time: string }[];

  constructor(config: IRruleConfig) {
    this.dtstart = config.dtstart;
    this.until = config.until;
    this.freq = config.freq ?? 'daily';
    this.interval = config.interval ?? 1;
    this.count = config.count;
    this.wkst = config.wkst ?? 'su';
    this.byWeekday = {
      su: config.byWeekday?.su ?? false,
      mo: config.byWeekday?.mo ?? false,
      tu: config.byWeekday?.tu ?? false,
      we: config.byWeekday?.we ?? false,
      th: config.byWeekday?.th ?? false,
      fr: config.byWeekday?.fr ?? false,
      sa: config.byWeekday?.sa ?? false
    };
    this.byMonth = {
      jan: config.byMonth?.jan ?? false,
      feb: config.byMonth?.feb ?? false,
      mar: config.byMonth?.mar ?? false,
      apr: config.byMonth?.apr ?? false,
      may: config.byMonth?.may ?? false,
      jun: config.byMonth?.jun ?? false,
      jul: config.byMonth?.jul ?? false,
      aug: config.byMonth?.aug ?? false,
      sep: config.byMonth?.sep ?? false,
      oct: config.byMonth?.oct ?? false,
      nov: config.byMonth?.nov ?? false,
      dec: config.byMonth?.dec ?? false
    };
    this.bySetPos = config.bySetPos;
    this.byMonthDay = config.byMonthDay;
    this.byWeekNo = config.byWeekNo;
    this.times = config.times;
  }
}

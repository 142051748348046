export const allergyLevels = [
  {
    id: 'mild',
    name: 'Mild'
  },
  {
    id: 'moderate',
    name: 'Moderate'
  },
  {
    id: 'severe',
    name: 'Severe'
  }
] as const;

export const allergyLevelIds = allergyLevels.map(({ id }) => id);

export type AllergyLevelId = (typeof allergyLevelIds)[number];

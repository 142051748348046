export const phoneTypes = [
  {
    id: 'mobile',
    name: 'Mobile',
    icon: 'smartphone'
  },
  {
    id: 'home',
    name: 'Home',
    icon: 'home'
  },
  {
    id: 'work',
    name: 'Work',
    icon: 'office-building'
  },
  {
    id: 'fax',
    name: 'Fax',
    icon: 'fax'
  },
  {
    id: 'direct',
    name: 'Direct',
    icon: 'phone'
  },
  {
    id: 'office',
    name: 'Office',
    icon: 'deskphone'
  }
] as const;

export const phoneTypeIds = phoneTypes.map(({ id }) => id);

export type PhoneTypeId = (typeof phoneTypeIds)[number];

export const logDetails = [
  {
    id: 'behavior',
    name: 'Behavior',
    service: 'behavior-logs',
    table: 'behaviorLogs',
    eager: {
      behaviorLog: {
        behavior: true
      }
    },
    icon: '/assets/icon/material/theater_comedy.svg',
    color: 'behavior-logs',
    displayLabel: 'Behavior Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/behaviors`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/behavior/${residentId}`,
    dependencyDescription:
      'Requires a user to record a behavior log to determine the dose to give.'
  },
  {
    id: 'bp',
    name: 'Blood Pressure',
    service: 'blood-pressure-logs',
    table: 'bloodPressureLogs',
    eager: {
      bloodPressureLog: true
    },
    icon: '/assets/icon/material/pulse.svg',
    color: 'blood-pressure-logs',
    displayLabel: 'Blood Pressure Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/blood-pressure`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/blood-pressure/${residentId}`,
    dependencyDescription:
      'Requires a user to record a blood pressure log to determine the dose to give.'
  },
  {
    id: 'bs',
    name: 'Blood Sugar',
    service: 'blood-sugar-logs',
    table: 'bloodSugarLogs',
    eager: {
      bloodSugarLog: true
    },
    icon: '/assets/icon/material/diabetes.svg',
    color: 'blood-sugar-logs',
    displayLabel: 'Blood Sugar Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/blood-sugar`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/blood-sugar/${residentId}`,
    dependencyDescription:
      'Requires a user to record a blood sugar log to determine the dose to give.'
  },
  {
    id: 'bodyCheck',
    name: 'Body Check',
    service: 'body-check-logs',
    table: 'bodyCheckLogs',
    eager: {
      bodyCheckLog: {
        observations: true
      }
    },
    icon: '/assets/icon/material/human_handsdown.svg',
    color: 'body-check-logs',
    displayLabel: 'Body Check Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/body-checks`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/body-check/${residentId}`,
    dependencyDescription:
      'Requires a user to record a body check log to determine the dose to give.'
  },
  {
    id: 'bm',
    name: 'Bowel Movement',
    service: 'bm-logs',
    table: 'bmLogs',
    eager: {
      bmLog: true
    },
    icon: '/assets/icon/material/poop.svg',
    color: 'bm-logs',
    displayLabel: 'Bowel Movement Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/bowel-movements`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/bowel-movements/${residentId}`,
    dependencyDescription:
      'Requires a user to record a bowel movement log to determine the dose to give.'
  },
  {
    id: 'food',
    name: 'Food',
    service: 'food-logs',
    table: 'foodLogs',
    eager: {
      foodLog: true
    },
    icon: '/assets/icon/material/silverware.svg',
    color: 'food-logs',
    displayLabel: 'Food Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/food`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/food/${residentId}`,
    dependencyDescription:
      'Requires a user to record a food log to determine the dose to give.'
  },
  {
    id: 'mood',
    name: 'Mood',
    service: 'mood-logs',
    table: 'moodLogs',
    eager: {
      moodLog: true
    },
    icon: '/assets/icon/material/emoticon.svg',
    color: 'mood-logs',
    displayLabel: 'Mood Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/mood`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/mood/${residentId}`,
    dependencyDescription:
      'Requires a user to record a mood log to determine the dose to give.'
  },
  {
    id: 'oxygen',
    name: 'Oxygen',
    service: 'oxygen-logs',
    table: 'oxygenLogs',
    eager: {
      oxygenLog: true
    },
    icon: '/assets/icon/alcomy/oxygen.svg',
    color: 'oxygen-logs',
    displayLabel: 'Oxygen Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/oxygen`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/oxygen/${residentId}`,
    dependencyDescription:
      'Requires a user to record an oxygen log to determine the dose to give.'
  },
  {
    id: 'position',
    name: 'Position',
    service: 'position-logs',
    table: 'positionLogs',
    eager: {
      positionLog: true
    },
    icon: '/assets/icon/material/airline_seat_flat.svg',
    color: 'position-logs',
    displayLabel: 'Position Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/position`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/position/${residentId}`,
    dependencyDescription:
      'Requires a user to record a position log to determine the dose to give.'
  },
  {
    id: 'seizure',
    name: 'Seizure',
    service: 'seizure-logs',
    table: 'seizureLogs',
    eager: {
      seizureLog: {
        symptoms: {
          symptom: true
        }
      }
    },
    icon: '/assets/icon/alcomy/seizure.svg',
    color: 'seizure-logs',
    displayLabel: 'Seizure Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/seizure`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/seizure/${residentId}`,
    dependencyDescription:
      'Requires a user to record a seizure log to determine the dose to give.'
  },
  {
    id: 'shower',
    name: 'Shower',
    service: 'shower-logs',
    table: 'showerLogs',
    eager: {
      showerLog: true
    },
    icon: '/assets/icon/material/bathtub.svg',
    color: 'shower-logs',
    displayLabel: 'Shower Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/shower`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/shower/${residentId}`,
    dependencyDescription: 'Requires a user to record a shower log'
  },
  {
    id: 'sleep',
    name: 'Sleep',
    service: 'sleep-logs',
    table: 'sleepLogs',
    eager: {
      sleepLog: true
    },
    icon: '/assets/icon/material/sleep.svg',
    color: 'sleep-logs',
    displayLabel: 'Sleep Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/sleep`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/sleep/${residentId}`,
    dependencyDescription:
      'Requires a user to record a sleep log to determine the dose to give.'
  },
  {
    id: 'note',
    name: 'Shift Note',
    service: 'shift-notes',
    table: 'shiftNotes',
    eager: {
      shiftNote: true
    },
    icon: '/assets/icon/material/account_balance_wallet.svg',
    color: 'shift-notes',
    displayLabel: 'Shift Notes',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/notes`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/notes/${residentId}`,
    dependencyDescription:
      'Requires a user to record a shift note to determine the dose to give.'
  },
  {
    id: 'temp',
    name: 'Temperature',
    service: 'temperature-logs',
    table: 'temperatureLogs',
    eager: {
      temperatureLog: true
    },
    icon: '/assets/icon/material/thermometer.svg',
    color: 'temperature-logs',
    displayLabel: 'Temperature Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/temperature`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/temperature/${residentId}`,
    dependencyDescription:
      'Requires a user to record a temperature log to determine the dose to give.'
  },
  {
    id: 'urine',
    name: 'Urine',
    service: 'urine-logs',
    table: 'urineLogs',
    eager: {
      urineLog: true
    },
    icon: '/assets/icon/alcomy/catheter.svg',
    color: 'urine-logs',
    displayLabel: 'Urine Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/urine`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/urine/${residentId}`,
    dependencyDescription:
      'Requires a user to record a urine log to determine the dose to give.'
  },
  {
    id: 'water',
    name: 'Water',
    service: 'water-logs',
    table: 'waterLogs',
    eager: {
      waterLog: true
    },
    icon: '/assets/icon/material/water.svg',
    color: 'water-logs',
    displayLabel: 'Water Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/water`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/water/${residentId}`,
    dependencyDescription:
      'Requires a user to record a water log to determine the dose to give.'
  },
  {
    id: 'weight',
    name: 'Weight',
    service: 'weight-logs',
    table: 'weightLogs',
    eager: {
      weightLog: true
    },
    icon: '/assets/icon/material/scale.svg',
    color: 'weight-logs',
    displayLabel: 'Weight Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/weight`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/weight/${residentId}`,
    dependencyDescription:
      'Requires a user to record a weight log to determine the dose to give.'
  }
] as const;

export const logTypeIds = logDetails.map(({ id }) => id);

export type LogDetail = (typeof logDetails)[number];

export type LogTypeId = LogDetail['id'];

export type LogNames = LogDetail['name'];

export const listTypes = [
  {
    id: 'seizure-symptoms',
    name: 'Seizure Symptoms'
  },
  {
    id: 'medication-destruction-methods',
    name: 'Medication Destruction Methods'
  },
  {
    id: 'medication-destruction-reasons',
    name: 'Medication Destruction Reasons'
  }
] as const;

export const listTypeIds = listTypes.map(({ id }) => id);

export type ListTypeId = (typeof listTypeIds)[number];

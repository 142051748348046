export const careTaskTypes = [
  {
    id: 'specific',
    name: 'Specific Times'
  },
  {
    id: 'allday',
    name: 'All Day'
  }
] as const;

export const careTaskTypeIds = careTaskTypes.map(({ id }) => id);

export type CareTaskTypeId = (typeof careTaskTypeIds)[number];

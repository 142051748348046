export const facilityUserStatuses = [
  {
    id: 'working',
    name: 'Working'
  },
  {
    id: 'meeting',
    name: 'In a meeting'
  },
  {
    id: 'break',
    name: 'On Break'
  },
  {
    id: 'sick',
    name: 'Out Sick'
  },
  {
    id: 'vacation',
    name: 'Vacationing'
  },
  {
    id: 'away',
    name: 'Away'
  }
] as const;

export const facilityUserStatusIds = facilityUserStatuses.map(({ id }) => id);

export type FacilityUserStatusId = (typeof facilityUserStatusIds)[number];

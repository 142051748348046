export const medicationInventoryTypes = [
  {
    id: 'resident',
    name: 'Resident'
  },
  {
    id: 'pop',
    name: 'Prep'
  },
  {
    id: 'destroy',
    name: 'Destroy'
  }
] as const;

export const medicationInventoryTypeIds = medicationInventoryTypes.map(
  ({ id }) => id
);

export type MedicationInventoryTypeId =
  (typeof medicationInventoryTypeIds)[number];

export const urineColors = [
  {
    id: 'clear',
    name: 'Clear'
  },
  {
    id: 'yellow',
    name: 'Yellow'
  },
  {
    id: 'dark',
    name: 'Dark Yellow'
  },
  {
    id: 'blood',
    name: 'Blood in Urine'
  }
] as const;

export const urineColorIds = urineColors.map(({ id }) => id);

export type UrineColorId = (typeof urineColorIds)[number];

export const positionSides = [
  {
    id: 'left',
    name: 'Left'
  },
  {
    id: 'back',
    name: 'Back/Center'
  },
  {
    id: 'right',
    name: 'Right'
  }
] as const;

export const positionSideIds = positionSides.map(({ id }) => id);

export type PositionSideId = (typeof positionSideIds)[number];

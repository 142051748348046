export const documentScopes = [
  {
    id: 'org',
    name: 'Organization',
    description: 'A document intended for the organization',
    field: 'orgId'
  },
  {
    id: 'fac',
    name: 'Facility',
    description: 'A document intended for a facility',
    field: 'facilityId'
  },
  {
    id: 'res',
    name: 'Resident',
    description: 'A document intended for a resident',
    field: 'residentId'
  },
  {
    id: 'emp',
    name: 'Employee',
    description: 'A document intended for an employee',
    field: 'facilityUserId'
  }
] as const;

export const documentScopeIds = documentScopes.map(({ id }) => id);

export type DocumentScopeId = (typeof documentScopeIds)[number];

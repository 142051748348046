import { RruleConfig } from '../rrule.interface';

export const documentDeadlineIntervals = [
  {
    id: 'noDeadline',
    name: 'No Deadline',
    description: 'The document will not have a specific due date',
    rruleConfig: null
  },
  {
    id: 'week',
    name: 'One Week',
    description: "The document is due one week after the 'based on' date",
    rruleConfig: new RruleConfig({
      freq: 'yearly',
      interval: 1,
      count: 1
    })
  },
  {
    id: 'twoWeeks',
    name: 'Two Weeks',
    description:
      "The document is due two weeks (14 days) after the 'based on' date",
    rruleConfig: new RruleConfig({
      freq: 'yearly',
      interval: 1,
      count: 1
    })
  },
  {
    id: 'numDays',
    name: 'A specific number of days',
    description: 'The document should be renewed in a specific number of days',
    rruleConfig: null
  }
] as const;

export const documentDeadlineIntervalIds = documentDeadlineIntervals.map(
  ({ id }) => id
);

export type DocumentDeadlineIntervalId =
  (typeof documentDeadlineIntervalIds)[number];

export const mealTypes = [
  {
    id: 'breakfast',
    name: 'Breakfast'
  },
  {
    id: 'lunch',
    name: 'Lunch'
  },
  {
    id: 'dinner',
    name: 'Dinner'
  },
  {
    id: 'morSnack',
    name: 'Morning Snack'
  },
  {
    id: 'aftSnack',
    name: 'Afternoon Snack'
  },
  {
    id: 'eveSnack',
    name: 'Evening Snack'
  },
  {
    id: 'snack',
    name: 'Snack'
  }
] as const;

export const mealTypeIds = mealTypes.map(({ id }) => id);

export type MealTypeId = (typeof mealTypeIds)[number];

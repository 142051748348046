export const californiaRegionalCenters = [
  {
    id: 'altaCaliforniaRegionalCenter',
    name: 'Alta California Regional Center'
  },
  {
    id: 'centralValleyRegionalCenter',
    name: 'Central Valley Regional Center'
  },
  {
    id: 'easternLosAngelesRegionalCenter',
    name: 'Eastern Los Angeles Regional Center'
  },
  { id: 'farNorthernRegionalCenter', name: 'Far Northern Regional Center' },
  {
    id: 'frankDLanternmanRegionalCenter',
    name: 'Frank D. Lanternman Regional Center'
  },
  {
    id: 'goldenGateRegionalCenter',
    name: 'Golden Gate Regional Center'
  },
  { id: 'harborRegionalCenter', name: 'Harbor Regional Center' },
  {
    id: 'inlandRegionalCenter',
    name: 'Inland Regional Center'
  },
  {
    id: 'kernRegionalCenter',
    name: 'Kern Regional Center'
  },
  { id: 'northBayRegionalCenter', name: 'North Bay Regional Center' },
  {
    id: 'northLosAngelesCountyRegionalCenter',
    name: 'North Los Angeles County Regional Center'
  },
  {
    id: 'redwoodCoastRegionalCenter',
    name: 'Redwood Coast Regional Center'
  },
  { id: 'regionalCenterOfEastBay', name: 'Regional Center of East Bay' },
  {
    id: 'regionalCenterOfOrangeCounty',
    name: 'Regional Center of Orange County'
  },
  { id: 'sanAndreasRegionalCenter', name: 'San Andreas Regional Center' },
  { id: 'sanDiegoRegionalCenter', name: 'San Diego Regional Center' },
  {
    id: 'sanGabriel/PomonaRegionalCenter',
    name: 'San Gabriel/Pomona Regional Center'
  },
  {
    id: 'southCentralLosAngelesRegionalCenter',
    name: 'South Central Los Angeles Regional Center'
  },
  { id: 'triCountiesRegionalCenter', name: 'Tri Counties Regional Center' },
  {
    id: 'valleyMountainRegionalCenter',
    name: 'Valley Mountain Regional Center'
  },
  { id: 'westsideRegionalCenter', name: 'Westside Regional Center' }
] as const;

export const californiaRegionalCenterIds = californiaRegionalCenters.map(
  ({ id }) => id
);

export type californiaRegionalCenterId =
  (typeof californiaRegionalCenterIds)[number];

export const medicalHistoryCategories = [
  {
    id: 'general',
    name: 'General'
  },
  {
    id: 'cardiovascular',
    name: 'Cardiovascular'
  },
  {
    id: 'respiratory',
    name: 'Respiratory'
  },
  {
    id: 'musculoskeletal',
    name: 'Musculoskeletal'
  },
  {
    id: 'skin',
    name: 'Skin'
  },
  {
    id: 'neurological',
    name: 'Neurological'
  },
  {
    id: 'gastrointestinal',
    name: 'Gastrointestinal'
  },
  {
    id: 'head',
    name: 'Head & Neck'
  },
  {
    id: 'salivary',
    name: 'Salivary'
  },
  {
    id: 'eyes',
    name: 'Eyes'
  },
  {
    id: 'ears',
    name: 'Ears'
  },
  {
    id: 'nose',
    name: 'Nose/Throat'
  },
  {
    id: 'pain',
    name: 'Pain'
  },
  {
    id: 'behavioral',
    name: 'Behavioral'
  },
  {
    id: 'dental',
    name: 'Dental'
  },
  {
    id: 'genital',
    name: 'Genitourinary'
  }
] as const;

export const medicalHistoryCategoryIds = medicalHistoryCategories.map(
  ({ id }) => id
);

export type MedicalHistoryCategoryId =
  (typeof medicalHistoryCategoryIds)[number];

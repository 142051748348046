export const urineLogTypes = [
  {
    id: 'catheter',
    name: 'Catheter'
  },
  {
    id: 'urinated',
    name: 'Urinated'
  },
  {
    id: 'didNotUrinate',
    name: 'Did Not Urinate'
  }
] as const;

export const urineLogTypeIds = urineLogTypes.map(({ id }) => id);

export type UrineLogTypeId = (typeof urineLogTypeIds)[number];

import { TLookup } from '../types/lookup.type';

export const medicationOrderStatuses = [
  {
    id: 'pending',
    name: 'Pending',
    description: 'The medication order has been queued to be processed.',
    filterOptions: {
      isVisible: true,
      position: 1
    }
  },
  {
    id: 'ordered',
    name: 'Ordered',
    description:
      'The pharmacy has received the medication order, and the facility is waiting for it to be filled.',
    filterOptions: {
      isVisible: true,
      position: 2
    }
  },
  {
    id: 'failed',
    name: 'Failed',
    description: 'The pharmacy failed to receive the medication order.',
    filterOptions: {
      isVisible: true,
      position: 5
    }
  },
  {
    id: 'filled',
    name: 'Filled',
    description: 'The pharmacy has filled the medication order',
    filterOptions: {
      isVisible: false
    }
  },
  // DEPRECATED: Remove after release
  {
    id: 'completed',
    name: 'Completed',
    description: 'The medication order has been received by the facility.',
    filterOptions: {
      isVisible: false
    }
  },
  {
    id: 'received',
    name: 'Received',
    description: 'The medication order has been received by the facility.',
    filterOptions: {
      isVisible: true,
      position: 3
    }
  },
  {
    id: 'cancelled',
    name: 'Cancelled',
    description: 'The medication order has been canceled.',
    filterOptions: {
      isVisible: true,
      position: 4
    }
  }
] as const satisfies TLookup[];

export const medicationOrderStatusIds = medicationOrderStatuses.map(
  ({ id }) => id
);

export type MedicationOrderStatusId = (typeof medicationOrderStatusIds)[number];

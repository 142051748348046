export const facilityUserTypes = [
  {
    id: 'staff',
    name: 'Staff'
  },
  {
    id: 'documentParticipant',
    name: 'Document Participant'
  }
] as const;

export const facilityUserTypeIds = facilityUserTypes.map(({ id }) => id);

export type FacilityUserTypeId = (typeof facilityUserTypeIds)[number];

export const allergyTypes = [
  {
    id: 'general',
    name: 'General',
    icon: 'local_florist'
  },
  {
    id: 'medication',
    name: 'Medication',
    icon: 'local_pharmacy'
  },
  {
    id: 'food',
    name: 'Food',
    icon: 'restaurant'
  }
] as const;

export const allergyTypeIds = allergyTypes.map(({ id }) => id);

export type AllergyTypeId = (typeof allergyTypeIds)[number];

export const bmTextures = [
  {
    id: 'none',
    name: 'None'
  },
  {
    id: 'solid',
    name: 'Solid'
  },
  {
    id: 'soft',
    name: 'Soft'
  },
  {
    id: 'diarrhea',
    name: 'Diarrhea'
  },
  {
    id: 'liquid',
    name: 'Liquid'
  }
] as const;

export const bmTextureIds = bmTextures.map(({ id }) => id);

export type BmTextureId = (typeof bmTextureIds)[number];

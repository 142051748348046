export const documentParticipantRoles = [
  {
    id: 'resident',
    name: 'Client or Resident',
    description: 'The individual that will receive care services.'
  },
  {
    id: 'residentRep',
    name: "Resident's Representative",
    description: 'Someone authorized to represent the resident.'
  },
  {
    id: 'facilityRep',
    name: "Facility's Representative",
    description: 'Someone authorized to represent the facility.'
  },
  {
    id: 'physician',
    name: 'Physician',
    description: "The resident's physician"
  },
  {
    id: 'editor',
    name: 'Editor',
    description: 'Someone authorized to edit the document but not sign it.'
  }
] as const;

export const documentParticipantRoleIds = documentParticipantRoles.map(
  ({ id }) => id
);

export type DocumentParticipantRoleId =
  (typeof documentParticipantRoleIds)[number];
